<template>
  <v-app>
    <div class="d-flex">
      <side v-if="show_side" style="width: 20%"></side>

      <v-main style="width: 80%; min-height: 100vh" class="">
        <router-view class="ma-10"></router-view>
      </v-main>
    </div>
    <!-- componnet -->
    <snackbar></snackbar>
    <progressBar></progressBar>
  </v-app>
</template>

<script>
import side from "./components/side";
import snackbar from "./components/snackbar.vue";
import progressBar from "./components/progressBar.vue";

export default {
  name: "App",

  components: {
    side,
    snackbar,
    progressBar,
  },

  // watchers

  watch: {
    $route: function (newPage, oldPage) {
      if (newPage.name === "login") {
        this.show_side = false;
      } else {
        this.show_side = true;

        // no token redirect
        this.token = localStorage.getItem("token");
        if (this.token === undefined || this.token === null) {
          this.$router.push("/login").catch(() => {});
        }
      }
    },
  },

  mounted(){
    if (this.$route.name === "login") {
        this.show_side = false;
      } else {
        this.show_side = true;

        // no token redirect
        this.token = localStorage.getItem("token");
        if (this.token === undefined || this.token === null) {
          this.$router.push("/login").catch(() => {});
        }
      }
  },

  data: () => ({
    show_side: false,
    token: "",
  }),
};
</script>

<style >
/* html {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
}
.v-application .v-application--wrap * {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.v-dialog,
.v-dialog .text-caption,
.text-subtitle-2 , .v-dialog .caption {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.toasted {
  direction: rtl;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} */

  /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8989899f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
</style>