import axios from "axios";
import moment from "moment";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import mmap from '../coordinates/coordinates.vue'


export default {

    components: {
        // map
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        mmap
    },

    data: () => ({

        date: '',
        modal: false,
        start_date: '',
        end_date: '',

        search_type: 'email',
        search_types: [{ title: 'Instagram', value: 'instagramId' }, { title: 'TikTok', value: 'tiktokId' }, { title: 'Phone', value: 'phone' }, { title: 'Email', value: 'email' }],
        edit_mode: false,
        search_text: '',
        venue: {},
        suggests: [],
        selected_suggests: '',
        combo_loading: false,
        logo: "",
        name: "",
        email: "",
        phone: "",
        instagram_id: "",
        tiktok_id: "",
        abn: "",
        country: "",
        city: "",
        venue_name: "",
        address: "",
        website: "",
        venue_id: '',
        map_link: '',
        membership: '',
        membership_list: [{ title: 'Bronze', value: 1 }, { title: 'Silver', value: 2 }, { title: 'Gold', value: 3 }],


        // map
        // apiKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5In0.eyJhdWQiOiIxOTEwOCIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5IiwiaWF0IjoxNjYwNTQ1MTI0LCJuYmYiOjE2NjA1NDUxMjQsImV4cCI6MTY2MzEzNzEyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.BllBK7n7RP8Dph2dRgDIz8mmNsd6SvgSFKz8vIYa_xTA4Nez3Z266a3a8OdipHNPHO-KbmBQgoaFBB6JHCIyA96eD7pYtKbX2ShkCcqnnASUQVyLu4rBHBnipQ2Eblam9QMUhXlIOFcEtdaLb7HgSTTinCCY1ZFi3Jf2YGdyW-FcxJjS32vuJ2TOY539lyofWpoptoraFQlQ11NP-O71qMnughSPpeS9DcClYKoeONhpydJEGuhjEzYCPs7yauD335g75tu27pAQpBhEYDqaWyZrOXeBzwm1AXHi7RhO28umCoSMyeAiP9MLtxxjnVwiuzTlm0n_GcisSOQRGuxx8A",
        address: "",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
            '',
        zoom: 10,
        center: [-37.6825654, 144.9657008],
        // markerLatLng: [-37.6825654, 144.9657008],

        venue_loc: [],


        // charts
        chartOptions31: {},
        series1: [],
        series2: [],
        series3: [],

        amount: '',
        explain: '',
        credit: '',

    }),

    mounted() {
        this.get_date()
        this.venue_id = localStorage.getItem('venueId')
        if (this.venue_id) {
            this.get_venue()
        }
        this.cancel()

    },

    methods: {

        checkdate() {
            let date1 = new Date(this.date[0]).getTime()
            let date2 = new Date(this.date[1]).getTime()

            if (date1 > date2) {
                this.start_date = this.date[1]
                this.end_date = this.date[0]
            }
            else {
                this.start_date = this.date[0]
                this.end_date = this.date[1]
            }

            const listDate = [];
            const startDate = this.start_date;
            const endDate = this.end_date;
            const dateMove = new Date(startDate);
            let strDate = startDate;

            while (strDate < endDate) {
                strDate = dateMove.toISOString().slice(0, 10);
                listDate.push(moment(strDate).format('DD-MM'));
                dateMove.setDate(dateMove.getDate() + 1);
            };
            console.log(listDate);

            this.chartOptions31 = {
                chart: {
                    height: 250,
                    type: 'line',
                    zoom: {
                        enabled: true
                    },
                    // toolbar: {
                    //     show: false
                    //   },
                },
                dataLabels: {
                    enabled: false
                },
                // tooltip: {
                //     enabled: false,
                // },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val >= 1000 ? (val / 1000).toFixed(1) + 'K' : val.toFixed(1)
                        }
                    }
                },
                xaxis: {
                    tickAmount: 3,
                    categories: listDate,

                }
            }

            this.get_business()
        },


        async get_suggests() {
            if (this.search_text && this.search_text.length > 1) {
                this.suggests = []
                this.combo_loading = true

                await axios({
                    method: 'GET',
                    url: '/admin/venues/search',
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    params: {
                        field: this.search_type,
                        value: this.search_text
                    }
                })
                    .then((res) => {
                        console.log(res.data.data.venues);
                        this.suggests = res.data.data.venues
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                        this.combo_loading = false

                    })
            }

        },

        // when mount page with venueId(localStoraage)
        async get_venue() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/venues/' + this.venue_id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.venue = res.data.data.venue
                    this.credit = this.venue.details.credit.toFixed(2)
                    this.logo = this.venue.details.logo
                    this.name = this.venue.name
                    this.abn = this.venue.details.abn
                    this.email = this.venue.email
                    this.phone = this.venue.phone
                    this.instagram_id = this.venue.instagramId.replace('https://instagram.com/', '')
                    this.tiktok_id = this.venue.tiktokId ? this.venue.tiktokId.replace("https://tiktok.com/", "") : ''
                    this.country = this.venue.country
                    this.city = this.venue.city
                    this.venue_name = this.venue.details.name
                    this.website = this.venue.details.websiteLink
                    this.address = this.venue.details.address
                    this.venue_loc = [this.venue.latitude, this.venue.longitude]
                    this.center = [this.venue.latitude, this.venue.longitude]
                    this.map_link = this.venue.mapLink
                    this.membership = this.venue.membership

                    this.create_series()
                    localStorage.setItem('membership', this.membership)
                    localStorage.setItem('venueId', this.venue._id)
                    this.venue_id = localStorage.getItem('venueId')
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        // when select from comboBox
        async search() {
            console.log(this.selected_suggests);
            if (this.search_text.length < 2) {
                this.suggests = []
            }
            else {
                this.$store.commit('progress_status', true)
                await axios({
                    method: 'GET',
                    url: '/admin/venues/' + this.selected_suggests._id,
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    params: {
                        field: this.search_type,
                        value: this.search_text
                    }
                })
                    .then((res) => {
                        console.log(res.data.data);
                        this.venue = res.data.data.venue
                        this.credit = this.venue.details.credit.toFixed(2)

                        this.logo = this.venue.details.logo
                        this.name = this.venue.name
                        this.abn = this.venue.details.abn
                        this.email = this.venue.email
                        this.phone = this.venue.phone
                        this.instagram_id = this.venue.instagramId.replace('https://instagram.com/', '')
                        this.tiktok_id = this.venue.tiktokId ? this.venue.tiktokId.replace("https://tiktok.com/", "") : ''
                        this.country = this.venue.country
                        this.city = this.venue.city
                        this.venue_name = this.venue.details.name
                        this.address = this.venue.details.address
                        this.website = this.venue.details.websiteLink
                        this.map_link = this.venue.mapLink
                        this.venue_loc = [this.venue.latitude, this.venue.longitude]
                        this.center = [this.venue.latitude, this.venue.longitude]
                        this.membership = this.venue.membership

                        this.create_series()
                        localStorage.setItem('membership', this.membership)
                        localStorage.setItem('venueId', this.selected_suggests._id)
                        this.venue_id = localStorage.getItem('venueId')
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                        // this.$store.commit('progress_status', false)

                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                    })
            }



        },

        async suspend(type) {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'PATCH',
                url: '/admin/venues/' + this.venue._id + '/suspend',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    action: type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (type === 'unblock') {
                        this.$store.commit('snackbar', { color: 'green', text: 'Venue unblocked successfully.' })
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'green', text: 'Venue blocked successfully.' })
                    }
                    this.get_venue()
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async handleImageUpload(event, num) {
            const [file] = document.getElementById('logo').files
            if (file) {
                document.getElementById('img').src = URL.createObjectURL(file)
            }
        },

        validation() {

            if (!this.name || !this.email || !this.phone || !this.instagram_id || !this.country || !this.city ||
                !this.abn || !this.venue_name || !this.address || !this.website || !this.map_link) {
                this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
            }
            else if (!this.email.includes('.') || !this.email.includes('@')) {
                this.$store.commit('snackbar', { color: 'red', text: 'Email address is not valid.' })
            }
            else if (this.phone.length !== 10) {
                this.$store.commit('snackbar', { color: 'red', text: 'Phone number is not valid.' })
            }
            else if (this.abn.length !== 11) {
                this.$store.commit('snackbar', { color: 'red', text: 'ABN must contain 11 digits.' })
            }
            else {
                this.send_image()
            }

        },

        async send_image() {
            const formData = new FormData();
            formData.append("image", document.getElementById('logo').files[0]);

            this.$store.commit('progress_status', true)
            await axios({
                method: 'POST',
                url: '/api/images',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: formData
            })
                .then((res) => {
                    console.log(res.data);
                    this.logo = res.data.data.filename
                    // localStorage.setItem('logo', this.logo)
                    this.save()
                })
                .catch((err) => {
                    console.log(err);
                    // this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    if (err.response.data.errors === 'empty') {
                        this.save()
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    }
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async save() {

            this.$store.commit('progress_status', true)
            await axios({
                method: 'PUT',
                url: '/admin/venues/' + this.venue._id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    instagramId: 'https://instagram.com/' + this.instagram_id,
                    tiktokId: this.tiktok_id ? 'https://tiktok.com/' + this.tiktok_id : '',
                    country: this.country,
                    city: this.city,
                    mapLink: this.map_link,
                    membership: this.membership,
                    details: {
                        abn: this.abn,
                        name: this.venue_name,
                        address: this.address,
                        websiteLink: this.website,
                        logo: this.logo,
                    },
                    latitude: this.venue_loc[0],
                    longitude: this.venue_loc[1]
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.logo = res.data.data.venue.details.logo
                    this.edit_mode = false
                    this.$store.commit('snackbar', { color: 'green', text: 'Venue saved successfully.' })
                    localStorage.setItem('membership', res.data.data.venue.membership)

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async update_credit(action) {
            if (!this.amount || !this.explain) {
                this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
            }
            else {
                this.$store.commit('progress_status', true)
                await axios({
                    method: 'PATCH',
                    url: '/admin/venues/' + this.venue_id + '/credit',
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    data: {
                        amount: this.amount,
                        details: this.explain,
                        action

                    }
                })
                    .then((res) => {
                        console.log(res.data);
                        this.credit = res.data.data.credit.toFixed(2)
                        this.$store.commit('snackbar', { color: 'green', text: 'Credit updated successfully.' })

                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                    })
            }

        },

        change_mode() {
            this.edit_mode = !this.edit_mode
        },

        open_link(link) {
            window.open(link, '_blank')
        },

        // map

        open_map() {
            document.getElementById('map').classList.remove('d-none')
            document.getElementById('map').classList.add('d-flex')
        },

        confirm() {
            this.venue_loc = [this.center.lat, this.center.lng]
            document.getElementById('map').classList.remove('d-flex')
            document.getElementById('map').classList.add('d-none')
        },

        cancel() {
            document.getElementById('map').classList.remove('d-flex')
            document.getElementById('map').classList.add('d-none')
        },

        zoomUpdated(zoom) {
            this.zoom = zoom;
        },

        async centerUpdated(center) {

            console.log(center);
            this.center = center;
            // this.lat = center.lat
            // this.lon = center.lng

            // await axios({
            //     method: 'GET',
            //     url: 'https://map.ir/reverse',
            //     headers: {
            //         'content-type': 'application/json',
            //         'x-api-key': "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5In0.eyJhdWQiOiIxOTEwOCIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5IiwiaWF0IjoxNjYwNTQ1MTI0LCJuYmYiOjE2NjA1NDUxMjQsImV4cCI6MTY2MzEzNzEyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.BllBK7n7RP8Dph2dRgDIz8mmNsd6SvgSFKz8vIYa_xTA4Nez3Z266a3a8OdipHNPHO-KbmBQgoaFBB6JHCIyA96eD7pYtKbX2ShkCcqnnASUQVyLu4rBHBnipQ2Eblam9QMUhXlIOFcEtdaLb7HgSTTinCCY1ZFi3Jf2YGdyW-FcxJjS32vuJ2TOY539lyofWpoptoraFQlQ11NP-O71qMnughSPpeS9DcClYKoeONhpydJEGuhjEzYCPs7yauD335g75tu27pAQpBhEYDqaWyZrOXeBzwm1AXHi7RhO28umCoSMyeAiP9MLtxxjnVwiuzTlm0n_GcisSOQRGuxx8A",
            //     },
            //     params: {
            //         lat: this.center.lat,
            //         lon: this.center.lng,
            //     }
            // })
            //     .then((res) => {
            //         // console.log(res);
            //         this.address = res.data.postal_address
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     })
            //     .finally(() => {
            //         this.$store.commit('progress_status', false)

            //     })


        },


        // chats
        create_series() {
            let ndata1 = []
            let ndata2 = []
            let ndata3 = []


            for (let i = 0; i < this.venue.numberOfCampaigns.length; i++) {
                ndata1.push(this.venue.numberOfCampaigns[i].total) // mounth
                ndata2.push(this.venue.avgSpend[i].total) // mounth
                ndata3.push(this.venue.income[i].total) // mounth
            }

            this.series1 = [{ name: "Total", data: ndata1 }] // mounth
            this.series2 = [{ name: "Total", data: ndata2 }] // mounth
            this.series3 = [{ name: "Total", data: ndata3 }] // mounth
        },

        get_date() {
            let labels = [];
            const popArray = () => {
                for (let i = 0; i <= 31; i++) {
                    labels.push(moment().subtract(i, "days").format('DD-MM'))
                }
                this.mounth = labels.reverse()
            }
            popArray();

            this.chartOptions31 = {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: true
                    },
                },
                dataLabels: {
                    enabled: false
                },
                // tooltip: {
                //     enabled: false,
                // },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val >= 1000 ? (val / 1000).toFixed(1) + 'K' : val.toFixed(1)
                        }
                    }
                },
                xaxis: {
                    tickAmount: 4,
                    categories: this.mounth,

                }

            }
        },

        fixed(num) {
            this.amount = (+num).toFixed(2)
        },
    }
};