import axios from "axios";

export default {

    data: () => ({
        search_type: 'both',
        search_types: [{ title :'Cash' , value : 'cash'}, {title :'Vouchers', value :'voucher'}, {title:'Both' , value:'both'}],
        venue_id: '',
        cash:[],
        vouchers:[],
    }),

    mounted() {
        this.venue_id = localStorage.getItem('venueId')
        if (!this.venue_id) {
            this.$router.push('/find_venue')
            this.$store.commit('snackbar', { color: 'blue', text: 'Please select a venue.' })
        }
        else{
            this.get_payments()
        }
    },

    methods: {
        async get_payments() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/venues/' + this.venue_id + '/payments',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    condition: this.search_type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.cash = res.data.data.payments
                    this.vouchers = res.data.data.vouchers

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },
    }
};