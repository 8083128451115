import axios from "axios";

export default {

    data: () => ({
        search_type: 'both',
        search_types: [{ title: 'Current', value: 'current' }, { title: 'Previous', value: 'prev' }, { title: 'Both', value: 'both' }],
        business_id: '',
        campaigns: [],
    }),

    mounted() {
        this.business_id = localStorage.getItem('businessId')
        if (!this.business_id) {
            this.$router.push('/find_business')
            this.$store.commit('snackbar', { color: 'blue', text: 'Please select a business.' })
        }
        else{
            this.get_campaigns()
        }
    },

    methods: {
        async get_campaigns() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/businesses/' + this.business_id + '/campaigns',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    condition: this.search_type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.campaigns = res.data.data.campaigns

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },
    }
};