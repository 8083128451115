import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";

export default {
  components: {
    // map
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },

  data: () => ({
      
        // map
        // apiKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5In0.eyJhdWQiOiIxOTEwOCIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5IiwiaWF0IjoxNjYwNTQ1MTI0LCJuYmYiOjE2NjA1NDUxMjQsImV4cCI6MTY2MzEzNzEyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.BllBK7n7RP8Dph2dRgDIz8mmNsd6SvgSFKz8vIYa_xTA4Nez3Z266a3a8OdipHNPHO-KbmBQgoaFBB6JHCIyA96eD7pYtKbX2ShkCcqnnASUQVyLu4rBHBnipQ2Eblam9QMUhXlIOFcEtdaLb7HgSTTinCCY1ZFi3Jf2YGdyW-FcxJjS32vuJ2TOY539lyofWpoptoraFQlQ11NP-O71qMnughSPpeS9DcClYKoeONhpydJEGuhjEzYCPs7yauD335g75tu27pAQpBhEYDqaWyZrOXeBzwm1AXHi7RhO28umCoSMyeAiP9MLtxxjnVwiuzTlm0n_GcisSOQRGuxx8A",
        address: "",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
            '',
        zoom: 10,
        center: [-37.6825654, 144.9657008],
        // markerLatLng: [-37.6825654, 144.9657008],

        venue_loc: {},

    }),

    methods:{
      // map

      add() {
            sessionStorage.setItem('coordinates' , JSON.stringify(this.center) )
            this.$router.push('/find_venue')
        },

        zoomUpdated(zoom) {
            this.zoom = zoom;
        },

        async centerUpdated(center) {

            console.log(center);
            this.center = center;
            // this.lat = center.lat
            // this.lon = center.lng

            // await axios({
            //     method: 'GET',
            //     url: 'https://map.ir/reverse',
            //     headers: {
            //         'content-type': 'application/json',
            //         'x-api-key': "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5In0.eyJhdWQiOiIxOTEwOCIsImp0aSI6ImE1ODVmNGM3ZGZiNjgzMjU5MmE0OTNjMmNkNDcwNDI0NTUzMWMxOTkxZDJkMDEzMmQ1NzgyYjdmMDAzM2QzNDRmNTUzMjdmNzFhN2NiZGI5IiwiaWF0IjoxNjYwNTQ1MTI0LCJuYmYiOjE2NjA1NDUxMjQsImV4cCI6MTY2MzEzNzEyNCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.BllBK7n7RP8Dph2dRgDIz8mmNsd6SvgSFKz8vIYa_xTA4Nez3Z266a3a8OdipHNPHO-KbmBQgoaFBB6JHCIyA96eD7pYtKbX2ShkCcqnnASUQVyLu4rBHBnipQ2Eblam9QMUhXlIOFcEtdaLb7HgSTTinCCY1ZFi3Jf2YGdyW-FcxJjS32vuJ2TOY539lyofWpoptoraFQlQ11NP-O71qMnughSPpeS9DcClYKoeONhpydJEGuhjEzYCPs7yauD335g75tu27pAQpBhEYDqaWyZrOXeBzwm1AXHi7RhO28umCoSMyeAiP9MLtxxjnVwiuzTlm0n_GcisSOQRGuxx8A",
            //     },
            //     params: {
            //         lat: this.center.lat,
            //         lon: this.center.lng,
            //     }
            // })
            //     .then((res) => {
            //         // console.log(res);
            //         this.address = res.data.postal_address
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     })
            //     .finally(() => {
            //         this.$store.commit('progress_status', false)

            //     })


        },
    }
};