import axios from "axios";

export default {

    data: () => ({
        new_businesses : [],
        selected :{},

    }),

    mounted() {
        this.get_new()
    },

    methods: {

        async get_new() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/businesses/pendings',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data);
                    this.new_businesses = res.data.data.businesses
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async verify(type) {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'PATCH',
                url: '/admin/businesses/pendings/'+ this.selected._id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data:{
                    verify : type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.new_businesses = res.data.data.businesses
                    this.selected = {}
                    this.$store.commit('snackbar', { color: 'green', text: 'action successfully' })
                    this.get_new()

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

    }
};