import axios from "axios";

export default {

    data: () => ({
        venues: [],
        selected: {},
    }),

    mounted() {
        this.get_venues()
    },

    methods: {
        async get_venues() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/venues/rejects',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.venues = res.data.data.userRejects;
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },
        open_link(link) {
            window.open( link, '_blank')
        },
    }
};