<template>
  <v-card
    color="primary"
    class="pa-0"
    width="100%"
    height="100vh"
    style="position: sticky; top: 0px; overflow-y: scroll"
    tile
    dark
  >
    <v-list class="pa-0" shaped color="primary" height="100vh">
      <!-- <v-avatar> -->
      <v-img class="mx-5 py-4" contain src="../assets/logo_prev_ui.png"></v-img>
      <!-- </v-avatar> -->
      <center>
        <div class="title my-2">Maxinify Admin</div>
      </center>
      <v-divider></v-divider>
      <v-list-item-group
        style="height: 79vh"
        color="secondary"
        class="d-flex flex-column justify-space-between pt-5"
      >
        <div>
          <v-list-item to="/stats">
            <v-list-item-icon>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Stats</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- user gorup -->
          <v-list-group group="user" no-action color="secondary">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Customers</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item to="/new_users" href="user">
              <v-list-item-content>
                <v-list-item-subtitle> New customer</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/find_user" href="user">
              <v-list-item-content>
                <v-list-item-subtitle> Find a customer</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/user_wallet" href="user">
              <!-- <v-list-item-icon>
              <v-icon>mdi-credit-card-settings</v-icon>
            </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-subtitle>Wallet</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/user_activities" href="user">
              <!-- <v-list-item-icon>
              <v-icon>mdi-credit-card-settings</v-icon>
            </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-subtitle>Activities</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/blocked_users" href="user">
              <v-list-item-content>
                <v-list-item-subtitle>Blocked list</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- business gorup -->
          <v-list-group no-action group="business" color="secondary">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-bag-checked</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Businesses</v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- <v-list-item to="/new_business">
              <v-list-item-content>
                <v-list-item-subtitle> New businesses</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item to="/find_business">
              <v-list-item-content>
                <v-list-item-subtitle> Find a business</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/business_campaigns">
              <v-list-item-content>
                <v-list-item-subtitle>Campaigns</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/business_deposit">
              <v-list-item-content>
                <v-list-item-subtitle>Payment History</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/blocked_business">
              <v-list-item-content>
                <v-list-item-subtitle>Blocked list</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- venue gorup -->
          <v-list-group no-action group="venue" color="secondary">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-silverware-fork-knife</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Venues</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item to="/new_venues">
              <v-list-item-content>
                <v-list-item-subtitle>New Venues</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/find_venue">
              <v-list-item-content>
                <v-list-item-subtitle> Find a venue</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/venue_payments">
              <v-list-item-content>
                <v-list-item-subtitle>Payments</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/venue_campaigns">
              <v-list-item-content>
                <v-list-item-subtitle>Campaigns</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/venue_menu">
              <v-list-item-content>
                <v-list-item-subtitle>Menu</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/venue_entertainment">
              <v-list-item-content>
                <v-list-item-subtitle>Entertainment</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/venue_deposit">
              <v-list-item-content>
                <v-list-item-subtitle>Payment History</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/blocked_venues">
              <v-list-item-content>
                <v-list-item-subtitle>Blocked list</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/rejected_venues">
              <v-list-item-content>
                <v-list-item-subtitle>Rejected Venues</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/campaigns">
            <v-list-item-icon>
              <v-icon>mdi-bullhorn</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Campaigns</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/accounting">
            <v-list-item-icon>
              <v-icon>mdi-calculator</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Accounting</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/questions">
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Questions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/withdraw">
            <v-list-item-icon>
              <v-icon>mdi-skip-backward</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Withdraw Requesets</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <div>
          <v-list-item to="/settings">
            <v-list-item-icon>
              <v-icon>mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Stats", icon: "mdi-chart-bar", link: "/stats" },
        { title: "Users", icon: "mdi-account-group", link: "/users" },
        { title: "Business", icon: "mdi-bag-checked", link: "" },
        { title: "Venues", icon: "mdi-silverware-fork-knife", link: "" },
        { title: "Campaigns", icon: "mdi-bullhorn", link: "" },
        { title: "Settings", icon: "mdi-cogs", link: "" },
        { title: "Log Out", icon: "mdi-logout" },
      ],
      opened: "user",
    };
  },
};
</script>