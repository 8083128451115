import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#9575CD', //purple
            secondary: '#1A237E', //pink
            darkblue: '#162464', //darkblue
            lighten: '#f3eff7',
            error: '#b71c1c',
          },
          dark:{
            // secondary:'#EDE7F6',
          }
        },
      },
});