import axios from "axios";
import { ModelObj } from 'vue-3d-model';
export default {
    components: { ModelObj },

    data: () => ({
        venue_id: '',
        istemp: false,
        android: '',
        ios: '',
        current: {},
        prev: [],
        ar_view_dialog: false,
        delete_item_dialog : false,
        selected_ar: '',
        membership  :1,
    }),

    mounted() {
        window.scrollTo(0, 0);
        this.venue_id = localStorage.getItem('venueId')
        // this.get_entertainments()
        this.membership = localStorage.getItem("membership")
    },

    methods: {

        async get_entertainments() {

            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: 'admin/venues/' + this.venue_id + '/entertainments',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data.entertainments);
                    // this.android = res.data.data.android
                    // this.ios = res.data.data.ios

                    this.current = res.data.data.entertainments.current
                    this.prev = res.data.data.entertainments.prev

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        // AR
        async preview() {


            if (!document.getElementById('ios').files[0] || !document.getElementById('android').files[0]) {
                // this.$store.commit('snackbar', { color: 'red', text: 'ddd' })

            }
            else {
                const formData = new FormData();
                formData.append("android", document.getElementById('android').files[0]);
                formData.append("ios", document.getElementById('ios').files[0]);
                console.log(formData);


                this.$store.commit('progress_status', true)
                await axios({
                    method: 'POST',
                    url: 'admin/ar',
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    data: formData
                })
                    .then((res) => {
                        console.log(res.data.data);
                        this.android = res.data.data.android
                        this.ios = res.data.data.ios
                        this.istemp = true
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                    })
            }

        },

        async add_ar() {

            const formData = new FormData();
            formData.append("android", document.getElementById('android').files[0]);
            formData.append("ios", document.getElementById('ios').files[0]);

            this.$store.commit('progress_status', true)
            await axios({
                method: 'POST',
                url: 'admin/venues/' + this.venue_id + '/entertainments',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: formData
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.get_entertainments()
                    this.istemp = false
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async remove_ar() {

            this.$store.commit('progress_status', true)
            await axios({
                method: 'DELETE',
                url: 'admin/venues/' + this.venue_id + '/entertainments',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.get_entertainments()
                    this.istemp = false
                    this.delete_item_dialog = false
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        open_ar_dialog(ar) {
            this.ar_view_dialog = true
            this.selected_ar = ar
        }

    }
};