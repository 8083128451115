import axios from "axios";

export default {

    data: () => ({
        // date picker
        date: [],
        min: '',
        max: '',
        modal: false,
        start_date: '',
        end_date: '',


        accounting: [],

        // charts
        chartOptions31: {},
        series1: [],
        series2: [],
        series3: [],
        series4: [],
        series5: [],
        series6: [],
        mounth: [],
    }),

    mounted() {
        this.get_charts()
    },

    methods: {
        async get_charts() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/accounting',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    startDate: this.start_date || undefined,
                    endDate: this.end_date || undefined,
                }

            })
                .then((res) => {
                    console.log(res.data.data);
                    this.accounting = res.data.data

                    // call functions
                    this.business_credit_chart(res.data.data.businessCredit)
                    this.customer_earn_chart(res.data.data.customerEarn)
                    this.customer_spend_chart(res.data.data.customerSpend)
                    this.remaining(res.data.data.remaining)
                    this.venue_credit(res.data.data.venueCredit)
                    this.live_credit(res.data.data.campaignsCreditPerDay)


                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        checkdate() {
            let date1 = new Date(this.date[0]).getTime()
            let date2 = new Date(this.date[1]).getTime()

            if (date1 > date2) {
                this.start_date = this.date[1]
                this.end_date = this.date[0]
            }
            else {
                this.start_date = this.date[0]
                this.end_date = this.date[1]
            }
            this.get_charts()
        },


        // create charts data
        business_credit_chart(info) {
            let x = []
            this.mounth = []
            for (let i = 0; i < info.length; i++) {
                // this.mounth = [{ name: "Total", data: info[i].createdAt }]
                this.mounth.push(info[i].createdAt)
                x.push(info[i].total)
            }
            this.series1 = [{
                'data': x,
                name: 'total'
            }]
            console.log(this.series1);

            // chart
            this.chartOptions31 = {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: true
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val >= 1000 ? (val / 1000).toFixed(1) + 'K' : val.toFixed(1)
                        }
                    }
                },
                xaxis: {
                    tickAmount: 4,
                    categories: this.mounth,

                }

            }
        },

        customer_earn_chart(info) {
            let x = []

            for (let i = 0; i < info.length; i++) {
                x.push(info[i].total)
            }
            this.series2 = [{
                'data': x,
                name: 'total'
            }]
            console.log(this.series2);

            // chart

        },

        customer_spend_chart(info) {
            let x = []

            for (let i = 0; i < info.length; i++) {
                x.push(info[i].total)
            }
            this.series3 = [{
                'data': x,
                name: 'total'
            }]
            console.log(this.series3);

            // chart

        },

        remaining(info) {
            let x = []

            for (let i = 0; i < info.length; i++) {
                x.push(info[i].total)
            }
            this.series4 = [{
                'data': x,
                name: 'total'
            }]
            console.log(this.series4);

            // chart

        },

        venue_credit(info) {
            let x = []

            for (let i = 0; i < info.length; i++) {
                x.push(info[i].total)
            }
            this.series5 = [{
                'data': x,
                name: 'total'
            }]
            console.log(this.series5);


        },

       live_credit(info) {
            let x = []

            for (let i = 0; i < info.length; i++) {
                x.push(info[i].total)
            }
            this.series6 = [{
                'data': x,
                name: 'total'
            }]
            console.log(this.series6);


        },

    }
};