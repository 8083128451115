import axios from "axios";

export default {
  
    data: () => ({
      withdraws : [],
    }),
  
    mounted() {
    this.get_withdraws()
    },
  
    methods:{
      async get_withdraws() {

        this.$store.commit('progress_status', true)
        await axios({
            method: 'GET',
            url: '/admin/withdraws',
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token")
            },
           
        })
            .then((res) => {
                console.log(res.data.data);
                this.withdraws = res.data.data.withdraws
            })
            .catch((err) => {
                console.log(err);
                this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
            })
            .finally(() => {
                this.$store.commit('progress_status', false)

            })
    },
    }
  };