<template>
  <div>
    <v-snackbar
      v-model="$store.state.snackbar_status"
      :timeout="3000"
      :color="$store.state.snackbar_color"
    >
      {{ $store.state.snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
// import { useStore } from "vuex";
// import { ref , watch , onMounted } from "vue";

export default {
  setup() {
    // const store = useStore();
  },
};
</script>