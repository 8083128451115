import axios from "axios";

export default {

    data: () => ({
        new_venues: [],
        selected: {},

        membership: '',
        membership_list: [{ title: 'Bronze', value: 1 }, { title: 'Silver', value: 2 }, { title: 'Gold', value: 3 }],


        accountOwnerName: '',
        bankName: '',
        BSB: '',
        accountNumber: '',
        bankCardNumber: '',
    }),

    mounted() {
        this.get_new()
    },

    methods: {

        async get_new() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/venues/pendings',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
            })
                .then((res) => {
                    console.log(res.data);
                    this.new_venues = res.data.data.venues
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async verify(type) {
            if (!this.accountOwnerName || !this.bankName || !this.BSB
                || !this.accountNumber || !this.bankCardNumber || !this.membership) {
                this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.'})
            }
            else{
                 this.$store.commit('progress_status', true)
            await axios({
                method: 'PATCH',
                url: '/admin/venues/pendings/' + this.selected._id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    verify: type,
                    accountOwnerName: this.accountOwnerName,
                    bankName: this.bankName,
                    BSB: this.BSB,
                    accountNumber: this.accountNumber,
                    bankCardNumber: this.bankCardNumber,
                    membership: this.membership,
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.new_venues = res.data.data.venues
                    this.selected = {}
                    if (type === true) {
                        this.$store.commit('snackbar', { color: 'green', text: 'Venue verified successfully.' })
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'green', text: 'Venue rejected successfully.' })
                    }
                    this.get_new()

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
            }
           
        },

        open_link(link) {
            window.open('http://' + link, '_blank')
        },
    }
};