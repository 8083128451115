import axios from "axios";
import moment from "moment";

export default {

    data: () => ({

        date: '',
        modal: false,
        start_date: '',
        end_date: '',


        search_type: 'email',
        search_types: [{ title: 'Instagram', value: 'instagramId' }, { title: 'TikTok', value: 'tiktokId' }, { title: 'Phone', value: 'phone' }, { title: 'Email', value: 'email' }],
        edit_mode: false,
        search_text: '',
        business: {},
        suggests: [],
        selected_suggests: '',
        combo_loading: false,

        logo: "",
        name: "",
        business_name: "",
        email: "",
        phone: "",
        instagram_id: "",
        tiktok_id: "",
        abn: "",
        country: "",
        city: "",

        business_id: '',

        // charts
        chartOptions31: {},
        series1: [],
        series2: [],

        // withdraw
        amount: '',
        explain: '',
        credit: '',



    }),

    mounted() {
        this.get_date()
        this.business_id = localStorage.getItem('businessId')
        if (this.business_id) {
            this.get_business()
        }
    },

    methods: {

        checkdate() {
            let date1 = new Date(this.date[0]).getTime()
            let date2 = new Date(this.date[1]).getTime()

            if (date1 > date2) {
                this.start_date = this.date[1]
                this.end_date = this.date[0]
            }
            else {
                this.start_date = this.date[0]
                this.end_date = this.date[1]
            }

            const listDate = [];
            const startDate = this.start_date;
            const endDate = this.end_date;
            const dateMove = new Date(startDate);
            let strDate = startDate;

            while (strDate < endDate) {
                strDate = dateMove.toISOString().slice(0, 10);
                listDate.push(moment(strDate).format('DD-MM'));
                dateMove.setDate(dateMove.getDate() + 1);
            };
            console.log(listDate);

            this.chartOptions31 = {
                chart: {
                    height: 250,
                    type: 'line',
                    zoom: {
                        enabled: true
                    },
                    // toolbar: {
                    //     show: false
                    //   },
                },
                dataLabels: {
                    enabled: false
                },
                // tooltip: {
                //     enabled: false,
                // },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val >= 1000 ? (val / 1000).toFixed(1) + 'K' : val.toFixed(1)
                        }
                    }
                },
                xaxis: {
                    tickAmount: 3,
                    categories: listDate,

                }

            }


            this.get_business()
        },

        async get_suggests() {
            if (this.search_text && this.search_text.length > 1) {
                this.suggests = []
                console.log(this.search_text);
                // this.$store.commit('progress_status', true)
                this.combo_loading = true

                await axios({
                    method: 'GET',
                    url: '/admin/businesses/search',
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    params: {
                        field: this.search_type,
                        value: this.search_text
                    }
                })
                    .then((res) => {
                        console.log(res.data.data.businesses);
                        this.suggests = res.data.data.businesses
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    })
                    .finally(() => {
                        // this.$store.commit('progress_status', false)
                        this.combo_loading = false

                    })
            }

        },

        // when mount page with businessId(localStoraage)
        async get_business() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/businesses/' + this.business_id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    startDate: this.start_date || undefined,
                    endDate: this.end_date || undefined,
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.business = res.data.data.business
                    this.credit = this.business.details.credit.toFixed(2)

                    this.logo = this.business.details.logo
                    this.abn = this.business.details.abn
                    this.name = this.business.name
                    this.business_name = this.business.details.name
                    this.email = this.business.email
                    this.phone = this.business.phone
                    this.instagram_id = this.business.instagramId.replace('https://instagram.com/', '')
                    this.tiktok_id = this.business.tiktokId ? this.business.tiktokId.replace("https://tiktok.com/", "") : ''
                    this.country = this.business.country
                    this.city = this.business.city

                    this.create_series()
                    localStorage.setItem('businessId', this.business._id)
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        // when select from comboBox
        async search() {
            if (this.search_text.length < 2) {
                this.suggests = []
            }
            else {
                this.$store.commit('progress_status', true)
                await axios({
                    method: 'GET',
                    url: '/admin/businesses/' + this.selected_suggests._id,
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    params: {
                        field: this.search_type,
                        value: this.search_text
                    }
                })
                    .then((res) => {
                        console.log(res.data);

                        this.business = res.data.data.business
                    this.credit = this.business.details.credit.toFixed(2)

                        this.logo = this.business.details.logo
                        this.abn = this.business.details.abn
                        this.name = this.business.name
                        this.business_name = this.business.details.name
                        this.email = this.business.email
                        this.phone = this.business.phone
                        this.instagram_id = this.business.instagramId.replace('https://instagram.com/', '')
                        this.tiktok_id = this.business.tiktokId ? this.business.tiktokId.replace("https://tiktok.com/", "") : ''
                        this.country = this.business.country
                        this.city = this.business.city
                        this.create_series()
                        localStorage.setItem('businessId', this.selected_suggests._id)
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                    })
            }

        },

        async suspend(type) {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'PATCH',
                url: '/admin/businesses/' + this.business._id + '/suspend',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    action: type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (type === 'unblock') {
                        this.$store.commit('snackbar', { color: 'green', text: 'Business unblocked successfully.' })
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'green', text: 'Business blocked successfully.' })
                    }
                    this.get_business()
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async handleImageUpload(event, num) {
            const [file] = document.getElementById('logo').files
            if (file) {
                document.getElementById('img').src = URL.createObjectURL(file)
            }
        },

        validation() {

            if (!this.name || !this.email || !this.phone || !this.instagram_id || !this.country || !this.city ||
                !this.abn || !this.business_name) {
                this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
            }
            else if (!this.email.includes('.') || !this.email.includes('@')) {
                this.$store.commit('snackbar', { color: 'red', text: 'Email address is not valid.' })
            }
            else if (this.phone.length !== 10) {
                this.$store.commit('snackbar', { color: 'red', text: 'Phone number is not valid.' })
            }
            else if (this.abn.length !== 11) {
                this.$store.commit('snackbar', { color: 'red', text: 'ABN must contain 11 digits.' })
            }
            else {
                this.send_image()
            }

        },

        async send_image() {
            const formData = new FormData();
            formData.append("image", document.getElementById('logo').files[0]);

            this.$store.commit('progress_status', true)
            await axios({
                method: 'POST',
                url: '/api/images',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: formData
            })
                .then((res) => {
                    console.log(res.data);
                    this.logo = res.data.data.filename
                    // localStorage.setItem('logo', this.logo)
                    this.save()
                })
                .catch((err) => {
                    console.log(err);
                    // this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    if (err.response.data.errors === 'empty') {
                        this.save()
                    }
                    else {
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    }
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async save() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'PUT',
                url: '/admin/businesses/' + this.business._id,
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    instagramId: 'https://instagram.com/' + this.instagram_id,
                    tiktokId: this.tiktok_id ? 'https://tiktok.com/' + this.tiktok_id : '',
                    country: this.country,
                    city: this.city,
                    details: {
                        abn: this.abn,
                        name: this.business_name,
                        logo: this.logo,
                    }
                }
            })
                .then((res) => {
                    console.log(res.data);

                    // this.business = res.data.data.business

                    this.logo = res.data.data.business.details.logo
                    // this.name = this.business.name
                    // this.abn = this.business.details.abn
                    // this.email = this.business.email
                    // this.phone = this.business.phone
                    // this.instagram_id = this.business.instagramId.replace('https://instagram.com/', '')
                    // this.tiktok_id = this.business.tiktokId ? this.business.tiktokId.replace("https://tiktok.com/", "") : ''
                    // this.country = this.business.country
                    // this.city = this.business.city
                    // this.business_name = this.business.details.name
                    // this.address = this.business.details.address
                    // this.website = this.business.details.websiteLink

                    this.edit_mode = false
                    this.$store.commit('snackbar', { color: 'green', text: 'Business saved successfully.' })

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async update_credit(action) {
            if (!this.amount || !this.explain) {
                this.$store.commit('snackbar', { color: 'red', text: 'Please fill out the form correctly.' })
            }
            else {
                this.$store.commit('progress_status', true)
                await axios({
                    method: 'PATCH',
                    url: '/admin/businesses/' + this.business._id + '/credit',
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token")
                    },
                    data: {
                        amount: this.amount,
                        details: this.explain,
                        action

                    }
                })
                    .then((res) => {
                        console.log(res.data);
                        this.credit = res.data.data.credit.toFixed(2)
                        this.$store.commit('snackbar', { color: 'green', text: 'Credit updated successfully.' })

                    })
                    .catch((err) => {
                        console.log(err);
                        this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                    })
                    .finally(() => {
                        this.$store.commit('progress_status', false)
                    })
            }

        },

        change_mode() {
            this.edit_mode = !this.edit_mode
        },

        open_link(link) {
            window.open(link, '_blank')
        },

        // chats
        create_series() {
            let ndata1 = []
            let ndata2 = []


            for (let i = 0; i < this.business.numberOfCampaigns.length; i++) {
                ndata1.push(this.business.numberOfCampaigns[i].total) // mounth
                ndata2.push(this.business.avgSpend[i].total) // mounth
            }

            this.series1 = [{ name: "Total", data: ndata1 }] // mounth
            this.series2 = [{ name: "Total", data: ndata2 }] // mounth
        },

        get_date() {
            let labels = [];
            const popArray = () => {
                for (let i = 0; i <= 31; i++) {
                    labels.push(moment().subtract(i, "days").format('DD-MM'))
                }
                this.mounth = labels.reverse()
            }
            popArray();

            this.chartOptions31 = {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: true
                    },
                    // toolbar: {
                    //     show: false
                    //   },
                },
                dataLabels: {
                    enabled: false
                },
                //  tooltip: {
                //     enabled: false,
                // },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val >= 1000 ? (val / 1000).toFixed(1) + 'K' : val.toFixed(1)
                        }
                    }
                },
                xaxis: {
                    tickAmount: 4,
                    categories: this.mounth,

                }

            }
        },

        fixed(num) {
            this.amount = (+num).toFixed(2)
        },
    }
};